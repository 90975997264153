import React from 'react';
import Select, { components } from 'react-select';

const MoreOptions = ({ counter }) => {
  return <div className="more-options"> {counter} избрани </div>;
};

const OptionContainer = (props) => {
  return (
    <components.Option
      {...props}
      className={`${props.isSelected && 'select-multioption-selected'}`}
    >
      {props.data.text}
    </components.Option>
  );
};

const LimitedChipsContainer = ({ children, hasValue, maxChips, ...props }) => {
  if (!hasValue) {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }

  const CHIPS_LIMIT = maxChips;
  const [chips, otherChildren] = children;
  const selectedOptionsCount = chips.length;
  const overflowCounter = chips.slice(CHIPS_LIMIT).length;
  const displayChips = chips.slice(overflowCounter, overflowCounter + CHIPS_LIMIT);

  return (
    <components.ValueContainer {...props} className="select-multioption-container">
      {overflowCounter > 0 ? <MoreOptions counter={selectedOptionsCount} /> : displayChips}
    </components.ValueContainer>
  );
};

const MultiSelectLimitedChips = ({
  options,
  onChange,
  value,
  maxChips,
  isOptionDisabled,
  hideSelectedOptions = false,
  className,
  ...selectSearchProps
}) => {
  let components = {
    ClearIndicator: () => null,
  };
  if (maxChips) {
    components = {
      ValueContainer: (inputProps) => <LimitedChipsContainer maxChips={maxChips} {...inputProps} />,
      Option: (inputProps) => <OptionContainer {...inputProps} />,
      ClearIndicator: () => null,
    };
  }

  return (
    <Select
      options={options}
      hideSelectedOptions={hideSelectedOptions}
      onChange={onChange}
      value={value || []}
      isMulti
      isOptionDisabled={isOptionDisabled}
      components={components}
      {...selectSearchProps}
      className={className}
    />
  );
};

export default MultiSelectLimitedChips;
