import InvestorsBuilding from '../../images/investors_building.png';
import Sell from '../../images/fsbo_icon_sell.svg';
import Buy from '../../images/fsbo_icon_buy.svg';
import NewBuilding from '../../images/fsbo_icon_new_building.svg';
import Rent from '../../images/fsbo_icon_rent.svg';
import SearchRent from '../../images/fsbo_icon_search_rent.svg';
import UnderConstruction from '../../images/fsbo_icon_under_construction.svg';
import Banners from '../../images/fsbo_icon_banners.svg';
import AddProject from '../../images/fsbo_icon_ad_project.svg';
import AddInvestorBuilding from '../../images/fsbo_icon_add_new_building_investors.svg';
import PaidPublication from '../../images/fsbo_icon_paid_publication.svg';
import PromoteAd from '../../images/fsbo_icon_promote_ad.svg';
import PromotePack from '../../images/fsbo_icon_promote_pack.svg';
import QuestionMark from '../../images/fsbo-faq-o.svg';

//20
export const buy = {
  id: 1,
  title: 'Продажби',
  path: '#',
  className: 'row-order',
  subNav: [
    {
      id: 20,
      title: `Продавам 
        (Добави обява)`,
      path: '/addProperty',
      state: { listed_under: 1 },
      image: Sell,
    },
    {
      id: 21,
      title: `Купувам 
        (Търся имот)`,
      path: '/buy-view?listedUnder=1&CurrentPage=1',
      state: { resetFilter: 'listedUnder=1' },
      image: Buy,
    },
  ],
};

export const rent = {
  id: 2,
  title: 'Наеми',
  path: '#',
  className: 'row-order',
  subNav: [
    {
      id: 25,
      title: `Търся да наема`,
      path: '/buy-view?listedUnder=2&CurrentPage=1',
      state: { resetFilter: 'listedUnder=2' },
      image: SearchRent,
    },
    {
      id: 24,
      title: `Давам под наем`,
      path: '/addProperty',
      state: { listed_under: 2 },
      image: Rent,
    },
  ],
};

export const buildings = {
  id: 3,
  title: 'Нови сгради',
  path: '#',
  className: 'row-order',
  subNav: [
    {
      id: 18,
      title: `Добави 
        нова сграда`,
      path: '/addBuilding',
      state: { is_building: true },
      image: AddProject,
    },
    {
      id: 19,
      title: `Търси 
        нова сграда`,
      path: '/buy-view?IsBuilding=true&CurrentPage=1',
      state: { resetFilter: 'IsBuilding=true' },
      image: AddInvestorBuilding,
    },
  ],
};

export const adds = {
  id: 4,
  title: 'Реклама',
  path: '#',
  className: 'row-order',
  subNav: [
    {
      id: 6,
      title: `Цени и обяви`,
      path: '/info-pages/PriceAndProperty',
      image: PromoteAd,
    },
    {
      id: 7,
      title: `Промоции`,
      path: '/info-pages/Promotion',
      image: PromotePack,
    },
    {
      id: 8,
      title: `Банери и лога`,
      path: '/info-pages/BannerAndLogo',
      image: Banners,
    },
  ],
};

export const help = {
  id: 10,
  title: 'Помощ',
  path: '#',
  subNav: [
    {
      id: 13,
      title: 'Имот съветник',
      path: '/info-pages/Advisor',
    },
    {
      id: 11,
      title: 'Често задавани въпроси',
      path: '/faq',
      image: QuestionMark,
    },
    {
      id: 12,
      title: 'Как да публикувам',
      path: '/info-pages/HowToPublish',
    },
  ],
};
