import React from 'react';
import clientTypes from './clientTypes.json';

const RegistrationClientType = ({ onSuccess, setIsBusiness }) => {
  return (
    <div className="pt-2">
      <span className="animated text">Вие сте:</span>
      {clientTypes.map((type) => {
        return (
          <button
            key={type.id}
            className="w-100 btn animated mt-2"
            onClick={() => {
              setIsBusiness(type.isBusiness);
              onSuccess();
            }}
          >
            <span>{type.label}</span>
          </button>
        );
      })}
    </div>
  );
};

export default RegistrationClientType;
