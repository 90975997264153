import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import Login from './Login';
import RegistrationSteps from './RegistrationSteps';
import ForgotPassword from './ForgotPassword';

const LoginMenu = ({ onSuccessLogin, customClass = '' }) => {
  const [modalComponent, setModalComponent] = useState('login');

  return (
    <Row>
      <Col>
        <div className="px-4 sidemenu-form-container">
          {modalComponent === 'login' && (
            <>
              <h3>Вход</h3>
              <Login providers={[]} buttonType="text" onSuccessLogin={onSuccessLogin} />
              <span
                className={`sidemenu-form-link${customClass}`}
                onClick={() => {
                  setModalComponent('register');
                }}
              >
                Регистрирайте се тук!
              </span>
              <span
                className={`sidemenu-form-link${customClass}`}
                onClick={() => {
                  setModalComponent('forgotPassword');
                }}
              >
                Забравена парола
              </span>
            </>
          )}
          {modalComponent === 'register' && (
            <>
              <RegistrationSteps
                customClass={customClass}
                emailVerification
                onSuccess={onSuccessLogin}
                initialStep="clientType"
                setModalComponent={setModalComponent}
              />
            </>
          )}
          {modalComponent === 'forgotPassword' && (
            <>
              <ForgotPassword textColor="white" />
              <span
                className={`sidemenu-form-link${customClass}`}
                onClick={() => {
                  setModalComponent('login');
                }}
              >
                Вход
              </span>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default LoginMenu;
