import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

const ToastDismiss = ({ ref, header, content, showToast, className }) => {
  return (
    <Toast innerRef={ref} isOpen={showToast} className={className}>
      <ToastHeader>{header || ''}</ToastHeader>
      <ToastBody>{content}</ToastBody>
    </Toast>
  );
};

ToastDismiss.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
  showToast: PropTypes.bool.isRequired,
};

export default ToastDismiss;
