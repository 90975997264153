import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Input, Label, Form } from 'reactstrap';
import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare';
import { FcGoogle } from '@react-icons/all-files/fc/FcGoogle';

import { ToastDismiss } from '../Common';
import RegistrationSteps from './RegistrationSteps';

import { login, socialLogin } from '@a1-ict/core/authSlice';
import { selectIsLoggedIn, selectUserData } from '@a1-ict/core/authSlice/selectors';
import { selectError } from '@a1-ict/core/dialogSlice/selectors';

import PasswordInput from '../Common/Inputs/PasswordInput';
import { handleInput, handleValidity } from './handleValidity';
import { clearError } from '@a1-ict/core/dialogSlice';

const getProviderButton = (provider, type) => {
  switch (provider) {
    case 'facebook':
      return (
        <>
          <FaFacebookSquare />
          {type === 'text' && <span>&nbsp;Продължете с Facebook</span>}
        </>
      );
    case 'google':
      return (
        <>
          <FcGoogle />
          {type === 'text' && <span>&nbsp;Продължете с Google</span>}
        </>
      );
  }
};

const Login = ({ providers, buttonType, onSuccessLogin }) => {
  const [socialContainerKey, setSocialContainerKey] = useState(1);
  const dispatch = useDispatch();

  const error = useSelector((state) => selectError(state, 'auth_login_error'));
  const isLogged = useSelector(selectIsLoggedIn);
  const userData = useSelector(selectUserData);

  useEffect(() => {
    if (isLogged) onSuccessLogin();
  }, [isLogged]);

  useEffect(() => {
    return () => dispatch(clearError({ name: 'auth_login_error' }));
  }, []);

  // const handleSocialLogin = (user) => {
  //   const {
  //     _token: { accessToken },
  //     _provider: provider,
  //   } = user;
  //   dispatch(socialLogin({ provider, access_token: accessToken }));
  // };

  // const handleSocialLoginFailure = (err) => {
  //   setSocialContainerKey(socialContainerKey + 1);
  // };

  const handleLogin = useCallback((e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    dispatch(login({ email, password }));
  }, []);

  return (
    <div key={socialContainerKey}>
      <ToastDismiss content={error} showToast={!!error} className="p-1 login-alert" />
      {!userData || !userData.is_social ? (
        <>
          <Form onSubmit={handleLogin}>
            <Label for="email">E-mail</Label>
            <Input
              type="text"
              name="email"
              id="email"
              title="Пример: office@fsbo.bg"
              required=""
              data-message="Моля, попълнете полето"
              onInvalid={handleValidity}
              onInput={handleInput}
            />
            <Label for="password">Парола</Label>
            <PasswordInput
              name="password"
              id="password"
              required=""
              data-message="Моля, попълнете полето"
              onInvalid={handleValidity}
              onInput={handleInput}
            />
            <button className="w-100 btn animated mt-2">
              <span>Вход</span>
            </button>
          </Form>

          {/* {providers.map((item, key) => {
            return (
              <SocialLoginButton
                key={`login-${item.provider}`}
                provider={item.provider}
                appId={item.appId}
                onLoginSuccess={handleSocialLogin}
                onLoginFailure={handleSocialLoginFailure}
                className={buttonType === 'text' && `login-button login-button_${item.provider} `}
              >
                {getProviderButton(item.provider, buttonType)}
              </SocialLoginButton>
            );
          })} */}
        </>
      ) : (
        <RegistrationSteps
          socialRegistration={true}
          // phoneVerification={userData && userData.phone_number ? false : true}
          emailVerification={userData && userData.email ? false : true}
          onSuccess={onSuccessLogin}
          initialStep="socialRegister"
        />
      )}
    </div>
  );
};

Login.propTypes = {
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      provider: PropTypes.oneOf(['facebook', 'google']),
      appId: PropTypes.string,
    })
  ).isRequired,
  buttonsType: PropTypes.oneOf(['icon', 'text']),
  onSuccessLogin: PropTypes.func.isRequired,
};

export default Login;
