import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const TooltipButton = ({
  children,
  position,
  name,
  className = '',
  button = false,
  trigger = 'legacy',
  containerClassName,
  ...rest
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  let navIconClass = tooltipOpen
    ? `navigation_burgericon navigation_burgericon-opened   mt-4 ${className}`
    : `navigation_burgericon mt-4 ${className}`;

  return (
    <span className='filters__child'>
      {!button ? (
        <div className="navigation_burger-container">
          <div id={name}>
            <span className={navIconClass}>&nbsp;</span>
          </div>
        </div>
      ) : (
        button
      )}
      <Tooltip
        placement={position}
        isOpen={tooltipOpen}
        toggle={toggle}
        target={name}
        trigger={trigger}
        innerClassName={containerClassName}
        {...rest}
      >
        {children}
      </Tooltip>
    </span>
  );
};

// TooltipButton.propTypes = {
//   header: PropTypes.string,
//   content: PropTypes.string.isRequired,
//   className: PropTypes.string,
//   showToast: PropTypes.bool.isRequired,
// };

export default TooltipButton;
