import React from 'react';
import { Link } from 'gatsby';

const LinkItem = ({ links, style, className }) => {
  return (
    links &&
    links.map((link) => (
      <Link key={link.title} to={`/${link.path}`} className={className} style={style}>
        {link.title}
      </Link>
    ))
  );
};

export default LinkItem;
