import React from 'react';
import Success from '../../images/new-building.png';

const RegistrationSuccess = ({ onSuccess }) => {
  return (
    <div className="pt-2 d-flex justify-content-between align-items-center flex-column ">
      <img src={Success} alt="success" width="40%" className="py-2" />
      <span className="animated text py-2">Профилът е създаден успешно!</span>
      <span className="animated text py-2">Благодарим Ви за регистрацията!</span>

      <button
        className="w-100 btn animated mt-2"
        onClick={() => {
          onSuccess();
        }}
      >
        <span>OK</span>
      </button>
    </div>
  );
};

export default RegistrationSuccess;
