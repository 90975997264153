import React, { useState, useRef } from 'react';
import { Input } from 'reactstrap';
import { AiFillEye } from '@react-icons/all-files/ai/AiFillEye';
import { AiFillEyeInvisible } from '@react-icons/all-files/ai/AiFillEyeInvisible';

const PasswordInput = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const init = useRef(true);

  const togglePassword = () => {
    !init.current && setPasswordShown(!passwordShown);
  };

  return (
    <div className="password-container">
      <Input
        type={passwordShown ? 'text' : 'password'}
        className="password-container_input"
        aria-required={false}
        onClick={(e) => {
          init.current = false;
          e.target.value = e.target.value;
        }}
        {...props}
      />
      <div onClick={togglePassword} className="password-container_button">
        {passwordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
      </div>
    </div>
  );
};

export default PasswordInput;
