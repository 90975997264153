import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useBreakpoint } from '@a1-ict/gatsby/utils/breakpoint';
import { buy, rent, buildings, adds, help } from '../sideNav/navData';
import NavbarMobile from './NavbarMobile';
import NavbarDesktop from './NavbarDesktop';
import PhoneVerificationModal from '../Login/PhoneVerificationModal';
import VerifyEmailModal from '../MyProfile/VerifyEmailModal';
import EmailVerificationModal from '../Login/EmailVerificationModal';

const Navbar = ({ bg = ' ', marginedContent, showMenu }) => {
  const data = useStaticQuery(graphql`
    {
      allStrapiServices(sort: { fields: order_index, order: ASC }) {
        edges {
          node {
            strapiId
            nodeType
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(transformOptions: { fit: COVER }, layout: FULL_WIDTH)
                }
                publicURL
              }
              url
            }
          }
        }
      }
      allStrapiInfoPages {
        nodes {
          title
          nodeType
          strapiId
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(transformOptions: { fit: COVER }, layout: FULL_WIDTH)
              }
              publicURL
            }
            url
          }
        }
      }
    }
  `);

  const { lg } = useBreakpoint();

  const serviceItems = data?.allStrapiServices.edges.map((edge, i) => {
    return {
      id: `service-${i}`,
      path: `/service/${edge.node.nodeType}/${edge.node.strapiId}`,
      title: edge.node.title,
      image: `${process.env.GATSBY_BACKEND}${edge.node.image.url}`,
    };
  });

  const services = {
    id: 'service-menu',
    title: 'Услуги',
    path: '#',
    subNav: [...serviceItems],
  };

  const allInfoNodeTypes = data.allStrapiInfoPages.nodes.map((node) => node.nodeType);

  let formatedHelp = { ...help };

  formatedHelp.subNav.forEach((sub, index) => {
    let lastOfPath = sub.path.split('/').pop();

    if (allInfoNodeTypes.includes(lastOfPath)) {
      let indexOfNode = data.allStrapiInfoPages.nodes.findIndex((x) => x.nodeType === lastOfPath);
      formatedHelp.subNav[
        index
      ].image = `${process.env.GATSBY_BACKEND}${data.allStrapiInfoPages.nodes[indexOfNode].image.url}`;
    }
  });

  const navData = [buy, rent, buildings, services, adds, formatedHelp];
  if (lg !== undefined)
    return (
      <>
        {/* <PhoneVerificationModal /> */}
        {/* <VerifyEmailModal /> */}
        <EmailVerificationModal />
        {lg ? (
          <NavbarMobile navData={navData} />
        ) : (
          <NavbarDesktop
            marginedContent={marginedContent}
            bg={bg}
            showMenu={showMenu}
            navData={navData}
          ></NavbarDesktop>
        )}
      </>
    );
  return null;
};

export default Navbar;
