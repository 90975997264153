import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PasswordInput from '../Common/Inputs/PasswordInput';
import { Input, Label, Form } from 'reactstrap';
import { ToastDismiss } from '../Common';
import { resetPassword, clearUserData } from '@a1-ict/core/authSlice';

import { setError, clearError } from '@a1-ict/core/dialogSlice';

import { selectError } from '@a1-ict/core/dialogSlice/selectors';
import { selectUserData } from '@a1-ict/core/authSlice/selectors';
import { handleInput, handleValidity } from './handleValidity';

const ResetPassword = () => {
  const passwordValidationRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/; // length greater than or equal to 6, contains at least 1 Uppercase letter, 1 Lowercase letter and 1 Number
  const [success, setSuccess] = useState(false);
  const error = useSelector((state) => selectError(state, 'auth_reset_password_error'));

  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearError(({ name: 'auth_reset_password_error' })))
    return () => dispatch(clearUserData());
  }, []);

  const handleReset = useCallback((e) => {
    e.preventDefault();
    setSuccess(false);
    const { new_password, passwordRepeat, code } = e.target;

    if (new_password.value !== passwordRepeat.value) {
      dispatch(
        setError({
          name: 'auth_reset_password_error',
          error: 'Паролите не съвпадат',
        })
      );
    } else if (!passwordValidationRegex.test(new_password.value)) {
      dispatch(
        setError({
          name: 'auth_reset_password_error',
          error:
            'Паролата трябва да е минимум 6 знака, да съдържа комбинация от цифри, главни и малки латински букви',
        })
      );
    } else {
      handleResetRequest({
        email: userData.email,
        code: code.value,
        new_password: new_password.value,
      });
    }
  }, []);

  const handleResetRequest = async (details) => {
    const result = await dispatch(
      resetPassword({
        ...details,
      })
    );

    if (result.type.includes('fulfilled')) {
      setSuccess(true);
      dispatch(
        setError({
          name: 'auth_reset_password_error',
          error: 'Вашата парола е променена успешно! Можете да влезете в профила си.',
        })
      );
    }
  };

  return (
    <>
      <ToastDismiss
        content={error}
        showToast={!!error}
        className={`p-1 ${success ? 'login-success' : 'login-alert'}`}
      />
      {!success && (
        <>
          <h3>Възстановяване на парола</h3>
          <Form onSubmit={handleReset}>
            <Label for="email">Код</Label>
            <Input required="" aria-required={false} type="text" name="code" id="code" />
            <Label for="new_password">Нова парола</Label>
            <PasswordInput
              name="new_password"
              id="new_password"
              autoComplete="new-password"
              required=""
              data-message="Моля, попълнете полето"
              onInvalid={handleValidity}
              onInput={handleInput}
            />
            <span className="animated text-small">
              Паролата трябва да е минимум 6 знака, да съдържа комбинация от цифри, главни и малки
              латински букви
            </span>
            <Label for="passwordRepeat">Повторете паролата</Label>
            <PasswordInput
              name="passwordRepeat"
              id="passwordRepeat"
              autoComplete="new-passwordRepeat"
              required=""
              data-message="Моля, попълнете полето"
              onInvalid={handleValidity}
              onInput={handleInput}
            />

            <button className="w-100 btn animated mt-2">
              <span>Потвърдете</span>
            </button>
          </Form>
        </>
      )}
    </>
  );
};

export default ResetPassword;
