import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Label, Form } from 'reactstrap';

import { ToastDismiss } from '../Common';

import { register } from '@a1-ict/core/authSlice';
import { selectUserData } from '@a1-ict/core/authSlice/selectors';
import { selectError } from '@a1-ict/core/dialogSlice/selectors';
import { handleInput, handleValidity } from './handleValidity';

const SocialRegistration = ({ onSuccess }) => {
  const dispatch = useDispatch();

  const error = useSelector((state) => selectError(state, 'login'));
  const userData = useSelector(selectUserData);

  const mandatoryFields = [
    { field: 'full_name', label: 'Име и фамилия на кирилица' },
    { field: 'email', label: 'E-mail' },
    { field: 'phone_number', label: 'Телефонен номер' },
  ];

  useEffect(() => {
    let registrationCompleted = true;
    mandatoryFields.forEach((fields) => {
      if (!userData[fields.field]) {
        registrationCompleted = false;
      }
    });
    if (registrationCompleted) onSuccess();
  }, []);

  const handleRegistration = useCallback(async (e) => {
    e.preventDefault();

    const request = { is_social: true, registration_id: userData.registration_id };

    mandatoryFields.forEach((fields) => {
      if (!userData[fields.field]) {
        request[fields.field] = e.target[fields.field].value;
      }
    });
    const result = await dispatch(
      register({
        request: request,
      })
    );
    if (result.payload?.ok) {
      onSuccess();
    }
  }, []);

  return (
    <>
      <ToastDismiss content={error} showToast={!!error} className="p-1 login-alert" />
      <Form onSubmit={handleRegistration}>
        {userData &&
          mandatoryFields.map((fields) => {
            if (!userData[fields.field]) {
              return (
                <>
                  <Label for={fields.field}>{fields.label} </Label>
                  <Input
                    type="text"
                    name={fields.field}
                    id={fields.field}
                    required=""
                    data-message="Моля, попълнете полето"
                    onInvalid={handleValidity}
                    onInput={handleInput}
                  />
                </>
              );
            }
          })}
        <button className="w-100 btn animated mt-2">
          <span>Регистрай се</span>
        </button>
      </Form>
    </>
  );
};

export default SocialRegistration;
