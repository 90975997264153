import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';
import { FaChevronUp } from '@react-icons/all-files/fa/FaChevronUp';

const SubMenu = ({ items }) => {
  const [openedDropdowns, setOpenedDropdowns] = useState({});

  return (
    <>
      <LinksList
        links={items}
        onChange={(selectedOptions) => setOpenedDropdowns(selectedOptions)}
        openedDropdowns={openedDropdowns}
      />
    </>
  );
};
export default SubMenu;

const LinksList = ({ links, onChange, openedDropdowns, extraClass = ' ' }) => {
  const handleDropdownClicked = (dropdownId) => {
    let openedDropdownsCopy = { ...openedDropdowns };
    if (openedDropdownsCopy.hasOwnProperty(dropdownId)) {
      delete openedDropdownsCopy[dropdownId];
    } else {
      openedDropdownsCopy[dropdownId] = {};
    }
    onChange(openedDropdownsCopy);
  };

  const handleSublinkClicked = (mainLinkKey, subLinkProperty) => {
    let openedDropdownsCopy = { ...openedDropdowns };
    openedDropdownsCopy[mainLinkKey] = subLinkProperty;
    onChange(openedDropdownsCopy);
  };

  return (
    <>
      {links.map((link) => {
        return (
          <div
            key={link.id}
            className={`link_wrapper ${openedDropdowns.hasOwnProperty(link.id) ? 'opened' : ''}`}
          >
            {' '}
            <LinkWrapper
              key={link.id}
              link={link}
              onChange={() => {
                handleDropdownClicked(link.id);
              }}
              className="links"
              isItOpened={openedDropdowns.hasOwnProperty(link.id)}
              extraClass={extraClass}
              state={openedDropdowns}
            >
              {' '}
            </LinkWrapper>
            {link.subNav && link.subNav.length > 0 && openedDropdowns.hasOwnProperty(link.id) && (
              <LinksList
                extraClass="sublink"
                links={link.subNav}
                openedDropdowns={openedDropdowns[link.id]}
                onChange={(subLinkProperty) => {
                  handleSublinkClicked(link.id, subLinkProperty);
                }}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

const LinkWrapper = ({ link, onChange, isItOpened, extraClass }) => {
  return (
    <Link
      to={link.path}
      onClick={() => onChange()}
      state={link.state || null}
      className={`links ${extraClass}`}
    >
      {link.title}
      {link.subNav && <div>{isItOpened ? <FaChevronUp /> : <FaChevronDown />}</div>}
    </Link>
  );
};
