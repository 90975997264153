import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, navigate } from 'gatsby';

import { FaUser } from '@react-icons/all-files/fa/FaUser';
import { FaRegBell } from '@react-icons/all-files/fa/FaRegBell';
import { FaPlusSquare } from '@react-icons/all-files/fa/FaPlusSquare';

import LogoWhite from '../../images/logo-white.svg';

import SideDrawer from './SideDrawer';
import UserMenu from '../UserMenu';
import NavToggler from '../Common/NavToggler';
import LoginModal from '../Login/LoginModal';

import SubMenu from '../sideNav/SubMenu';
import Notifications from './Notifications';
import useDeepEffect from '@a1-ict/core/utils/useDeepEffect';
import { selectUserData } from '@a1-ict/core/authSlice/selectors';
import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';
import { setLoggedIn } from '@a1-ict/core/authSlice';
import { selectNotifications } from '@a1-ict/fsbo/notificationsSlice/selectors';
import { fetchNotifications } from '@a1-ict/fsbo/notificationsSlice';
import useComponentVisible from '../Common/customHooks/useComponentVisible';

const NavbarMobile = ({ navData }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const userData = useSelector(selectUserData);

  const userDrawer = useDialog('rightDrawer');
  const menuDrawer = useDialog('leftDrawer');
  const login = useDialog('login');

  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const logUser = () => {
    dispatch(setLoggedIn());
  };

  let hamburgerDrawerClasses = 'sidedrawer_leftdrawer ' + (menuDrawer.isOpen ? ' show' : ' ');

  let userDrawerClasses = 'sidedrawer_rightdrawer ' + (userDrawer.isOpen ? ' show' : ' ');

  useEffect(() => {
    return () => {
      userDrawer.close();
      menuDrawer.close();
    };
  }, []);

  useDeepEffect(() => {
    if (userData?.id) dispatch(fetchNotifications({ queryParams: {} }));
  }, [userData]);

  return (
    <>
      <LoginModal
        onSuccessLogin={() => {
          login.toggle();
          logUser();
        }}
      />
      <SideDrawer classList={hamburgerDrawerClasses}>
        <div className="side_nav">
          <nav aria-label="side-nav" className="side_nav_navigation d-flex flex-column">
            <SubMenu items={navData}></SubMenu>
            {/* {links} */}
          </nav>
        </div>
      </SideDrawer>
      <SideDrawer classList={userDrawerClasses}>
        <UserMenu linkPaddingX={2} customClass="-white" />
      </SideDrawer>
      <div
        className={`navigation_wrapper navigation_wrapper-mobile ${
          menuDrawer.isOpen ? ' moveToRight' : userDrawer.isOpen ? ' moveToLeft' : ' '
        }`}
      >
        <div className="navigation navigation_mobile ">
          <div className="navigation_icon_button">
            <div className="add_listing_wrapper">
              <div className="navigation_icon">
                <NavToggler
                  navIsOpen={menuDrawer.isOpen}
                  setNavIsOpen={menuDrawer.toggle}
                ></NavToggler>
              </div>
            </div>
          </div>
          <div className="navigation_logo navigation_logo-mobile">
            <Link to="/">
              <img src={LogoWhite} alt="logo" width="139px" height="60px" />
            </Link>
          </div>
          <div className="navigation__buttons d-flex align-items-center h-100">
            <div className="h-100 d-flex position-relative align-items-center">
              <button
                onClick={() => navigate('/addProperty')}
                style={{ outline: 'none' }}
                className="btn  p-3 navigation__bell"
              >
                <FaPlusSquare fontSize={18} className="color-default"></FaPlusSquare>
              </button>
            </div>
            {userData && userData.email_verified && (
              <div ref={ref} className="h-100 d-flex position-relative align-items-center">
                <button
                  onClick={() => setIsComponentVisible(!isComponentVisible)}
                  style={{ outline: 'none' }}
                  className="btn p-3 navigation__bell"
                >
                  <FaRegBell fontSize={18} className="color-default"></FaRegBell>
                  <span className="navigation__bell__unread">
                    {notifications?.total_unread ?? 0}
                  </span>
                </button>
                <Notifications show={isComponentVisible}></Notifications>
              </div>
            )}
            <div
              className="navigation_icon_button "
              onClick={userDrawer.toggle}
              onKeyDown={userDrawer.toggle}
              role="button"
              tabIndex="0"
            >
              <div className="navigation_icon">
                <FaUser color="white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarMobile;
