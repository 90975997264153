import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { verifyNewEmail } from '@a1-ict/fsbo/userSlice';
import { clearError, selectError } from '@a1-ict/core/dialogSlice';
import { usePrivateFunction } from '../Security/usePrivateFunction';
import { ToastDismiss } from '../Common';

import { Row, Col, Label, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { verifyEmail } from '@a1-ict/core/authSlice';
import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';

const VerifyEmailModal = ({ email, show, setShow, handleVerified }) => {
  const { toggle, isOpen } = useDialog('email-verify');

  const [code, setCode] = useState('');

  const { secure } = usePrivateFunction();
  const dispatch = useDispatch();
  const verifyCodeError = useSelector((state) => selectError(state, 'auth_verify_email_error'));

  const closeModal = () => {
    toggle();
  };

  useEffect(() => {
    setCode('');
    dispatch(clearError({ name: 'auth_verify_email_error' }));
  }, [show]);

  const handleInput = (e) => {
    const { value } = e.target;
    setCode(value);
  };

  const handleCodeCheck = () => {
    secure(async () => {
      const result = await dispatch(verifyEmail({ code: Number(code), email: email }));
      if (result?.payload?.ok) {
        setShow(false);
        handleVerified();
      }
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={closeModal} size="lg">
      <ModalHeader toggle={closeModal}>Верификация на e-mail адрес</ModalHeader>
      <ModalBody className="p-0 d-flex flex-column justify-content-center">
        <Row className="justify-content-center">
          <Col xs={12} sm={7} className="mt-3 d-flex flex-column justify-content-center">
            <ToastDismiss
              content={verifyCodeError}
              showToast={!!verifyCodeError}
              className="p-1 login-alert"
            />
          </Col>
        </Row>
        <Row className="justify-content-center my-3">
          <Col xs={12} sm={7} className="mb-3 d-flex flex-column justify-content-center px-5">
            <div className=" d-flex flex-column justify-content-center mb-2">
              <Label for="code" className="text-truncate">
                Въведете кода от получения e-mail
              </Label>
              <Input
                id="code"
                type="email"
                title="Пример: office@fsbo.bg"
                name="code"
                className="form-control-lg"
                value={code}
                onChange={handleInput}
              />
            </div>
            <div className="d-flex justify-content-center">
              <button className="btn animated px-3 py-2" onClick={handleCodeCheck}>
                <span className="">Потвърдете</span>
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default VerifyEmailModal;
