import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { settings } from '../../settings';

import { selectIsLoggedIn, selectUserData } from '@a1-ict/core/authSlice/selectors';
import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';

export const usePrivateFunction = () => {
  const [isRequestInitiated, setIsRequestInitiated] = useState(false);
  const [requestAction, setRequestAction] = useState(() => {});
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userDetails = useSelector(selectUserData);

  const modalLogin = useDialog('login');
  const modalPhoneVerify = useDialog('phone-verify');
  const modalEmailVerify = useDialog('email-verify');

  useEffect(() => {
    if (isLoggedIn && userDetails?.email_verified && isRequestInitiated) {
      //  if (isLoggedIn && userDetails?.phone_verified && isRequestInitiated) {
      requestAction();
      setIsRequestInitiated(false);
      setRequestAction(() => {});
    }
  }, [isLoggedIn, userDetails]);

  const secure = (action) => {
    if (!settings.storage.isBrowser) return null;
    if (isLoggedIn && userDetails?.email_verified) {
      action();
    } else if (!isLoggedIn) {
      setIsRequestInitiated(true);
      setRequestAction(() => action);
      modalLogin.open();
      return false;
    } else {
      setIsRequestInitiated(true);
      setRequestAction(() => action);
      modalEmailVerify.open();
      return false;
    }
  };

  return {
    secure: (action) => secure(action),
  };
};
