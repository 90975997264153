import React, { useState, useEffect, useRef } from 'react';
import * as signalR from '@microsoft/signalr';
import { Link } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { useTransition, animated } from 'react-spring';
import { fetchNotifications } from '@a1-ict/fsbo/notificationsSlice';
import { selectNotifications } from '@a1-ict/fsbo/notificationsSlice/selectors';
import { settings } from '../../settings';
import { toast } from 'react-toastify';

const Notifications = ({ show }) => {
  const dispatch = useDispatch();
  const socket = useRef(null);
  const { items: notifications } = useSelector(selectNotifications);

  useEffect(async () => {
    const accessToken = await settings.storage.getAccessToken();

    if (accessToken) {
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.GATSBY_BACKEND}/notificationHub?sessionId=${accessToken}`)
        .build();

      hubConnection.serverTimeoutInMilliseconds = 30 * 1000;
      hubConnection.keepAliveIntervalInMilliseconds = 15 * 1000;

      hubConnection.on('NotificationReceived', (payload) => {
        dispatch(fetchNotifications({ queryParams: {} }));
        toast.info('Ново известие! Кликнете камбанката за повече информация.', {
          position: toast.POSITION.TOP_RIGHT,
          className: 'bg-primary'
        });
      });
      socket.current = hubConnection;

      const start = async () => {
        try {
          await hubConnection.start();
        } catch (err) {
          setTimeout(start, 10000);
        }
      };

      start();
    }
  }, []);

  useEffect(() => {
    return () => {
      if (socket.current) socket.current.stop();
    };
  }, []);

  let transition = useTransition(show, {
    from: { opacity: 0 },
    enter: {
      opacity: 1,
    },
    leave: { opacity: 0 },
  });

  return (
    <>
      {transition(
        (style, show) =>
          show && (
            <animated.div style={style} className="navigation__nots box-default ">
              <div className="px-2 px-xs-0 py-0 navigation__nots__body">
                {notifications?.length > 0 ? (
                  <ul className="pl-0 my-0">
                    {notifications.map((note, index) => {
                      return (
                        <li
                          key={index}
                          className={`d-flex align-items-center  ${note.read ? 'bg-default' : 'bg-grey-2'
                            }`}
                        >
                          <Link to={`/dashboard/messages/${note.id}`} className="text-decoration-none">
                            <div className=" d-flex flex-column p-sm-1 p-xs-1">
                              <span className='text-small'>{note.title}</span>
                              {/* <div className="note_description">
                                <p>{note.body}</p>
                              </div> */}

                              <span style={{ fontSize: ".6rem" }} className="text-danger text-small">{note.created_on}</span>
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p className="p-2 text-center"> Няма нови известия </p>
                )}
              </div>
              <div className="p-2 navigation__nots__footer text-center">
                <Link to="/dashboard/messages">Виж всички</Link>
              </div>
            </animated.div>
          )
      )}
    </>
  );
};

export default Notifications;
