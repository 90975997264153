import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Label, Form } from 'reactstrap';
import { ToastDismiss } from '../Common';
import ResetPassword from './ResetPassword';

import { forgotPassword, setUserData } from '@a1-ict/core/authSlice';

import { selectError } from '@a1-ict/core/dialogSlice/selectors';
import { handleInput, handleValidity } from './handleValidity';
import { clearError } from '@a1-ict/core/dialogSlice';

const ForgotPassword = ({ textColor = "black" }) => {
  const [forgotTriggered, setForgotTriggered] = useState(false);
  const dispatch = useDispatch();

  const error = useSelector((state) => selectError(state, 'auth_forgot_password_error'));

  const handleForgotPassword = useCallback((e) => {
    e.preventDefault();
    const email = e.target.email.value;
    dispatch(setUserData({ email }));
    handleForgottenPasswordRequest(email);
  }, []);

  const handleForgottenPasswordRequest = async (email) => {
    const result = await dispatch(forgotPassword({ email }));
    if (result.payload && result.payload.ok) {
      setForgotTriggered(true);
    }
  };

  useEffect(() => {
    dispatch(clearError({ name: "auth_forgot_password_error" }))
  }, [forgotTriggered])

  return !forgotTriggered ? (
    <>
      <ToastDismiss content={error} showToast={!!error} className="p-1 login-alert" />
      <h3>Възстановяване на парола</h3>
      <p style={{ color: textColor }} className="">Въведете e-mail адрес, с който сте се регистрирали, за да Ви изпратим код за възстановяване.
      </p>
      <Form onSubmit={handleForgotPassword}>
        <Label for="email">E-mail</Label>
        <Input
          type="email"
          name="email"
          id="email"
          title="Пример: office@fsbo.bg"
          required=""
          data-message="Моля, попълнете полето"
          onInvalid={handleValidity}
          onInput={handleInput}
        />
        <button className="w-100 btn animated mt-2">
          <span>Изпрати код</span>
        </button>
      </Form>
    </>
  ) : (
    <ResetPassword />
  );
};

export default ForgotPassword;
