import { selectError } from '@a1-ict/core/dialogSlice';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

// set the defaults
export const ComponentWithError = React.createContext({
  setComponentWithError: () => {},
  setElementOffset: () => {},
});

function SideDrawer({ children, classList }) {
  const [componentWithError, setComponentWithError] = useState('');
  const [elementOffset, setElementOffset] = useState(0);

  const errorRef = useRef(null);

  const value = { setComponentWithError, setElementOffset };

  let error = useSelector((state) => selectError(state, componentWithError));

  useEffect(() => {
    if (!!error || null) {
      errorRef.current.scrollTop = elementOffset;
    }
  }, [error]);

  return (
    <ComponentWithError.Provider value={value}>
      <div
        id="drawer"
        ref={errorRef}
        style={{ scrollBehavior: 'smooth' }}
        className={classList + ' sidedrawer'}
      >
        {children}
      </div>
    </ComponentWithError.Provider>
  );
}

export default SideDrawer;
