import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FaRegHeart } from '@react-icons/all-files/fa/FaRegHeart';
import { FaRegUser } from '@react-icons/all-files/fa/FaRegUser';
import { FaHome } from '@react-icons/all-files/fa/FaHome';
import { FaEnvelope } from '@react-icons/all-files/fa/FaEnvelope';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { MdAddBox } from '@react-icons/all-files/md/MdAddBox';
import { MdExitToApp } from '@react-icons/all-files/md/MdExitToApp';
import { ImFilesEmpty } from '@react-icons/all-files/im/ImFilesEmpty';

import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { LoginMenu, PhoneVerification } from './Login';

import { selectIsLoggedIn, selectUserData } from '@a1-ict/core/authSlice/selectors';
import { setLoggedIn } from '@a1-ict/core/authSlice';
import EmailVerification from './Login/EmailVerification';

const menu = [
  {
    icon: <FaRegUser size={16} className="mr-2"></FaRegUser>,
    title: 'Моят Профил',
    path: 'my-profile',
  },
  {
    icon: <FaHome size={16} className="mr-2"></FaHome>,
    title: 'Моите обяви',
    path: 'my-properties',
  },
  {
    icon: <MdAddBox size={16} className="mr-2"></MdAddBox>,
    title: 'Добавяне на обява',
    path: 'add-property',
  },
  {
    icon: <FaRegHeart size={16} className="mr-2"></FaRegHeart>,
    title: 'Любими имоти',
    path: 'favourites',
  },
  // {
  //   icon: <FaSearch size={16} className="mr-2"></FaSearch>,
  //   title: 'Запазени търсения',
  //   path: 'searches',
  // },
  {
    icon: <ImFilesEmpty size={16} className="mr-2"></ImFilesEmpty>,
    title: 'Портфейл',
    path: 'wallet',
  },
  {
    icon: <FaEnvelope size={16} className="mr-2"></FaEnvelope>,
    title: 'Съобщения',
    path: 'messages',
  },
  {
    icon: <MdExitToApp size={16} className="mr-2"></MdExitToApp>,
    title: 'Изход',
    path: 'logout',
  },
];

const UserMenu = ({ customClass = '', linkPaddingX = 1 }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let url = location.pathname.split('/');

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userData = useSelector(selectUserData);

  const logUser = () => {
    dispatch(setLoggedIn());
  };

  return isLoggedIn ? (
    userData?.email_verified ? (
      <ul className={`user-nav ${customClass}`}>
        {menu.map(({ title, path, icon }) => {
          return (
            <Link
              to={path !== 'logout' ? `/dashboard/${path}` : `/logout`}
              key={title}
              className={`user-nav_item d-flex  px-${linkPaddingX} py-3 ${
                url.includes(path) && url.includes('dashboard') ? ' active' : ''
              }`}
            >
              <div className="user-nav_item_content">
                {icon}
                {title}
              </div>
            </Link>
          );
        })}
      </ul>
    ) : (
      <div className="">
        {/* <PhoneVerification onSuccess={logUser}></PhoneVerification> */}
        <EmailVerification onSuccess={logUser}></EmailVerification>
        <Link
          style={{ backgroundColor: '#e9a081' }}
          to={`/logout`}
          className={`user-nav_item d-flex  px-4 py-3 `}
        >
          <div className="user-nav_item_content">
            <MdExitToApp size={16} className="mr-2"></MdExitToApp>
            Изход
          </div>
        </Link>
      </div>
    )
  ) : (
    <div className="pt-5">
      <LoginMenu
        customClass={customClass}
        onSuccessLogin={() => {
          logUser();
        }}
      />
    </div>
  );
};

export default UserMenu;
