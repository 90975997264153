import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, navigate } from 'gatsby';
import { FaUser } from '@react-icons/all-files/fa/FaUser';
import { FaRegBell } from '@react-icons/all-files/fa/FaRegBell';

import LogoWhite from '../../images/logo-white.svg';
import LogoBlack from '../../images/logo-black.svg';

import { TooltipButton } from '../Common';
import LoginModal from '../Login/LoginModal';
import UserMenu from '../UserMenu';
import Navigation from './Navigation';
import Notifications from './Notifications';
import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';

import { setLoggedIn } from '@a1-ict/core/authSlice';
import { selectIsLoggedIn, selectUserData } from '@a1-ict/core/authSlice/selectors';
import { fetchNotifications } from '@a1-ict/fsbo/notificationsSlice';
import { selectNotifications } from '@a1-ict/fsbo/notificationsSlice/selectors';
import useComponentVisible from '../Common/customHooks/useComponentVisible';

const NavbarDesktop = ({ bg, marginedContent, showMenu, navData }) => {
  const [scrollOfTop, setScrollOfTop] = useState(0);

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { toggle } = useDialog('login');

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const notifications = useSelector(selectNotifications);
  const userData = useSelector(selectUserData);

  const dispatch = useDispatch();

  const logUser = () => {
    dispatch(setLoggedIn());
  };

  useEffect(() => {
    dispatch(fetchNotifications({ queryParams: {} }));

    onPageScroll();
    window.addEventListener('scroll', onPageScroll);
    return () => {
      window.removeEventListener('scroll', onPageScroll);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) dispatch(fetchNotifications({ queryParams: {} }));
  }, [isLoggedIn]);

  const onPageScroll = () => {
    setScrollOfTop(window.pageYOffset);
  };

  const getColor = (bg) => {
    switch (bg) {
      case 'white':
        return { backgroundColor: 'white' };
      default:
        return { backgroundColor: 'transparent' };
    }
  };

  return (
    <div
      style={getColor(bg)}
      className={` navigation_wrapper navigation_wrapper-desktop  ${
        scrollOfTop > 80 ? ' scrolled' : ' '
      } ${marginedContent ? ' shadowed' : ''}`}
    >
      <div className={`navigation navigation_desktop px-xl-3 px-lg-2`}>
        <div className="navigation_logo ">
          <Link to={`/`}>
            <img
              src={scrollOfTop > 80 ? LogoBlack : bg === 'white' ? LogoBlack : LogoWhite}
              alt="logo"
              width="139px"
              height="70px"
            />
          </Link>
        </div>
        <nav className={`navigation_nav nav `}>
          <Navigation bg={bg} items={navData} showSublinkIcon={false}></Navigation>
        </nav>

        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="navigation__buttons d-flex align-items-center h-100">
            {userData && userData.email_verified && (
              <div ref={ref} className="h-100 d-flex position-relative align-items-center">
                <button
                  onClick={() => setIsComponentVisible(!isComponentVisible)}
                  style={{ outline: 'none' }}
                  className="btn p-3 navigation__bell "
                >
                  <FaRegBell fontSize={18} className="color-default"></FaRegBell>
                  <span className="navigation__bell__unread desktop">
                    {notifications?.total_unread ?? 0}
                  </span>
                </button>
                <Notifications show={isComponentVisible}></Notifications>
              </div>
            )}

            <Link to={'/addProperty'}>
              <button className="btn navigation__add animated">
                <span>Добави обява</span>
              </button>
            </Link>
          </div>
          <div className="navigation_icon ml-2">
            {isLoggedIn && showMenu ? (
              <TooltipButton position="bottom" name="user-menu" className="primary">
                <UserMenu customClass="dark"></UserMenu>
              </TooltipButton>
            ) : (
              <>
                {!isLoggedIn && (
                  <>
                    <FaUser color={`${bg === 'white' ? 'black' : 'white'}`} onClick={toggle} />
                    <LoginModal
                      onSuccessLogin={() => {
                        toggle();
                        logUser();
                      }}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarDesktop;
