import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Label, Form } from 'reactstrap';

import { ToastDismiss } from '../Common';

import { resendCode, verifyEmail } from '@a1-ict/core/authSlice';
import { selectUserData } from '@a1-ict/core/authSlice/selectors';
import { toast } from 'react-toastify';
import { selectError } from '@a1-ict/core/dialogSlice/selectors';
import { handleInput, handleValidity } from './handleValidity';
import { useBreakpoint } from '@a1-ict/gatsby/utils/breakpoint';

const EmailVerification = ({ onSuccess, textColor = '' }) => {
  const { email } = useSelector(selectUserData);
  const { lg } = useBreakpoint();
  console.log(email, 'email');
  const error = useSelector((state) => selectError(state, 'auth_verify_email_error'));

  const dispatch = useDispatch();

  const handleVerification = useCallback((e) => {
    e.preventDefault();
    const { code } = e.target;
    handleVerify(code.value);
  }, []);

  const handleVerify = async (code) => {
    const result = await dispatch(verifyEmail({ email, code: code }));
    if (result?.payload?.ok) {
      onSuccess();
    }
  };

  const handleResendEmailCode = async () => {
    const result = await dispatch(resendCode({ username: email }));

    if (result?.payload?.ok)
      toast.success('Успешно изпратен код!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    else
      toast.error(result.error.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
  };

  return (
    <div className="py-3">
      <ToastDismiss content={error} showToast={!!error} className="p-1 login-alert" />
      <Form
        style={{ color: textColor ? textColor : lg ? 'white' : 'black' }}
        onSubmit={handleVerification}
      >
        <Label for="code">Моля въведете кода, който получихте на посочения e-mail</Label>
        <Input
          type="text"
          name="code"
          id="code"
          required=""
          data-message="Моля, попълнете полето"
          onInvalid={handleValidity}
          onInput={handleInput}
        />
        <button className="w-100 btn animated mt-2">
          <span>Продължете</span>
        </button>
      </Form>
      <div
        style={{ cursor: 'pointer' }}
        onClick={handleResendEmailCode}
        className="w-100 color-default animated mt-2 mb-3 text-center "
      >
        <span>Изпрати код отново</span>
      </div>
    </div>
  );
};

export default EmailVerification;
