import React from 'react';
import { Modal, ModalBody, Col, Row } from 'reactstrap';

import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';
import PhoneVerification from './PhoneVerification';

const PhoneVerificationModal = ({ modalSize = 'lg' }) => {
    const { toggle, isOpen } = useDialog('phone-verify');

    const closeModal = () => {
        toggle();
    };

    return (
        <Modal isOpen={isOpen} scrollable={true} size={modalSize} toggle={closeModal} backdrop={false}>
            <ModalBody className="py-0 pl-md-0">
                <Row>

                    <Col xs={12} className="p-4">
                        <span className="pl-2">Номерът Ви не е верифициран!</span>
                        <PhoneVerification onSuccess={closeModal} textColor="black" /></Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default PhoneVerificationModal;
