import Login from './Login';
import RegistrationSteps from './RegistrationSteps';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import LoginModal from './LoginModal';
import LoginMenu from './LoginMenu';
import PhoneVerification from './PhoneVerification';
import EmailVerification from './EmailVerification';
import SocialRegistration from './SocialRegistration';

export {
  Login,
  RegistrationSteps,
  Registration,
  ForgotPassword,
  LoginModal,
  LoginMenu,
  ResetPassword,
  PhoneVerification,
  SocialRegistration,
  EmailVerification,
};
