import React from 'react';
import { Modal, ModalBody, Col, Row } from 'reactstrap';

import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';
import EmailVerification from './EmailVerification';

const EmailVerificationModal = ({ modalSize = 'md' }) => {
  const { toggle, isOpen } = useDialog('email-verify');

  const closeModal = () => {
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      centered
      scrollable={true}
      size={modalSize}
      toggle={closeModal}
      backdrop={true}
    >
      <ModalBody className="py-0 ">
        <Row>
          <Col xs={12} className="px-4 pt-4 justify-content-center">
            <span>Номерът Ви не е верифициран!</span>{' '}
            <EmailVerification onSuccess={closeModal} textColor="black" />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default EmailVerificationModal;
