import React, { useEffect, useCallback, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Label, Form } from 'reactstrap';
import { Link } from 'gatsby';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastDismiss } from '../Common';
import PasswordInput from '../Common/Inputs/PasswordInput';

import { register, setUserData } from '@a1-ict/core/authSlice';

import { setError, clearError } from '@a1-ict/core/dialogSlice';
import { selectError } from '@a1-ict/core/dialogSlice/selectors';
import { ComponentWithError } from '../navigation/SideDrawer';
import {
  bulstatValidationRegex,
  cyrillicRegex,
  passwordValidationRegex,
} from '../../data/validationsRegex';
import { handleInput, handleValidity } from './handleValidity';

const Registration = ({ onSuccess, isBusiness }) => {
  const { setComponentWithError, setElementOffset } = useContext(ComponentWithError);
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const errorContainer = useRef();

  const error = useSelector((state) => selectError(state, 'auth_register_error'));

  useEffect(() => {
    const element = document.getElementById('errorDiv');
    setComponentWithError('auth_register_error');
    if (element) {
      setElementOffset(element.offsetTop);
    }

    return () => dispatch(clearError({ name: 'auth_register_error' }));
  }, []);

  useEffect(() => {
    if (!!error) {
      recaptchaRef.current.reset();
      errorContainer.current.scrollIntoView();
    }
  }, [error]);

  const handleRegistration = useCallback((e) => {
    e.preventDefault();
    const { email, password, msisdn, name, passwordRepeat, company_uic, consentBox } = e.target;

    const recaptchaToken = recaptchaRef.current.getValue();
    dispatch(clearError({ name: 'auth_register_error' }));
    if (!recaptchaToken) {
      dispatch(
        setError({
          name: 'auth_register_error',
          error: 'Моля, потвърдете, че не сте робот!',
        })
      );
    } else if (!consentBox.checked) {
      dispatch(
        setError({
          name: 'auth_register_error',
          error: 'Съгласете се с условията',
        })
      );
    } else if (!passwordValidationRegex.test(password.value)) {
      dispatch(
        setError({
          name: 'auth_register_error',
          error:
            'Паролата трябва да е минимум 6 знака, да съдържа комбинация от цифри, главни и малки латински букви',
        })
      );
    } else if (isBusiness && !bulstatValidationRegex.test(company_uic.value)) {
      dispatch(
        setError({
          name: 'auth_register_error',
          error: 'Невалиден булстат',
        })
      );
    } else if (isBusiness && !cyrillicRegex.test(name.value)) {
      dispatch(
        setError({
          name: 'auth_register_error',
          error: 'Име на фирма трябва да е на кирилица',
        })
      );
    } else if (password.value !== passwordRepeat.value) {
      dispatch(
        setError({
          name: 'auth_register_error',
          error: 'Паролите не съвпадат',
        })
      );
    } else {
      dispatch(clearError({ name: 'auth_register_error' }));
      dispatch(setUserData({ email: email.value }));

      const payload = {
        is_business: isBusiness,
        password: password.value,
        email: email.value,
        phone_number: msisdn.value,
        name: name.value,
        token: recaptchaToken,
      };

      if (isBusiness) payload.company_uic = company_uic.value;

      handleRegister(payload);
    }
  }, []);

  const handleRegister = async (payload) => {
    const result = await dispatch(
      register({
        request: payload,
      })
    );
    if (result.payload?.ok) {
      onSuccess();
    }
  };

  const onRecaptchaChange = (value) => {
    if (!value) recaptchaRef.current.reset();
  };

  return (
    <>
      <div id="errorDiv" ref={errorContainer}>
        <ToastDismiss content={error} showToast={!!error} className="p-1 login-alert" />
      </div>
      <Form onSubmit={handleRegistration}>
        <Label for="name">
          {!isBusiness ? 'Име и фамилия на кирилица' : 'Име на фирмата на кирилица'}
          <span className="color-primary">
            &nbsp;<span className="color-primary">&nbsp;*</span>
          </span>
        </Label>
        <Input
          type="text"
          name="name"
          id="name"
          required=""
          data-message="Моля, попълнете полето"
          onInvalid={handleValidity}
          onInput={handleInput}
        />
        {isBusiness && (
          <>
            <Label for="company_uic">
              ЕИК/Булстат<span className="color-primary">&nbsp;*</span>
            </Label>
            <Input
              type="number"
              name="company_uic"
              id="company_uic"
              required=""
              data-message="Моля, попълнете полето"
              onInvalid={handleValidity}
              onInput={handleInput}
            />
          </>
        )}
        <Label for="email">
          E-mail<span className="color-primary">&nbsp;*</span>
        </Label>
        <Input
          type="email"
          name="email"
          id="email"
          required=""
          data-message="Моля, попълнете полето"
          onInvalid={handleValidity}
          onInput={handleInput}
          title="Пример: office@fsbo.bg"
        />
        <div className=" pb-1">
          <Label for="password">
            Парола<span className="color-primary">&nbsp;*</span>
          </Label>
          <PasswordInput
            name="password"
            id="password"
            autoComplete="new-password"
            required=""
            data-message="Моля, попълнете полето"
            onInvalid={handleValidity}
            onInput={handleInput}
          />
          <span className="animated text-small">
            Паролата трябва да е минимум 6 знака, да съдържа комбинация от цифри, главни и малки
            латински букви
          </span>
        </div>
        <Label for="passwordRepeat">
          Повторете паролата<span className="color-primary">&nbsp;*</span>
        </Label>
        <Input
          type="password"
          name="passwordRepeat"
          id="passwordRepeat"
          required=""
          data-message="Моля, попълнете полето"
          onInvalid={handleValidity}
          onInput={handleInput}
        />
        <Label for="msisdn">
          Мобилен номер <span className="color-primary">&nbsp;*</span>
        </Label>
        <Input
          type="text"
          name="msisdn"
          id="msisdn"
          required=""
          placeholder="приема формат 0ХХХХХХХХХ"
          data-message="Моля, попълнете полето"
          onInvalid={handleValidity}
          onInput={handleInput}
        />
        <Label check className="mt-2 d-block">
          <input type="checkbox" id="consentBox" name="consentBox" />
          Съгласявам се с&nbsp;
          <a href={`/terms/common`} className="animated underline" target="_blank">
            Условията за регистрация
          </a>
        </Label>
        <ReCAPTCHA
          className="recaptcha"
          sitekey={process.env.RECAPTCHA_SITE_KEY}
          onChange={onRecaptchaChange}
          ref={recaptchaRef}
        />
        <button className="w-100 btn animated mt-2">
          <span>Регистрирайте се</span>
        </button>
      </Form>
    </>
  );
};

export default Registration;
