import React from "react";

const NavToggler = ({ setNavIsOpen, navIsOpen }) => {
  let navIconClass = navIsOpen
    ? "navigation_burgericon navigation_burgericon-opened"
    : "navigation_burgericon";

  return (
    <div
      className="navigation_burger"
      onKeyDown={() => setNavIsOpen(!navIsOpen)}
      onClick={() => setNavIsOpen(!navIsOpen)}
      role="button"
      tabIndex={0}
    >
      <div className="navigation_burger-container">
        <span className={navIconClass}>&nbsp;</span>
      </div>
    </div>
  );
};

export default NavToggler;
