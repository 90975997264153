import React, { useState } from 'react';

import Registration from './Registration';
import PhoneVerification from './PhoneVerification';
import EmailVerification from './EmailVerification';
import RegistrationClientType from './RegistrationClientType';
import RegistrationSuccess from './RegistrationSuccess';
import SocialRegistration from './SocialRegistration';
import { navigate } from 'gatsby';

const steps = {
  clientType: 'clientType',
  register: 'register',
  socialRegister: 'socialRegister',
  // phoneVerify: 'phoneVerify',
  emailVerify: 'emailVerify',
  success: 'success',
};

const RegistrationSteps = ({
  customClass = '',
  phoneVerification = false,
  emailVerification = false,
  socialRegistration = false,
  onSuccess,
  initialStep = steps.register,
  setModalComponent,
}) => {
  const currentFlowSteps = [steps.clientType, steps.register];
  const [step, setStep] = useState(initialStep);
  const [isBusiness, setIsBusiness] = useState('');

  if (socialRegistration) currentFlowSteps.push(steps.socialRegister);
  if (phoneVerification) currentFlowSteps.push(steps.phoneVerify);
  if (emailVerification) currentFlowSteps.push(steps.emailVerify);

  currentFlowSteps.push(steps.success);

  const nextStep = (step) => {
    const currentStep = currentFlowSteps.indexOf(step);
    if (currentStep === currentFlowSteps.length - 1) {
      onSuccess();
      return;
    }
    if (currentStep !== currentFlowSteps[-1]) {
      setStep(currentFlowSteps[currentStep + 1]);
    }
  };

  const getRegistrationStep = (step) => {
    switch (step) {
      case steps.clientType:
        return (
          <RegistrationClientType
            onSuccess={() => {
              nextStep(steps.clientType);
            }}
            setIsBusiness={setIsBusiness}
          />
        );
      case steps.register:
        return (
          <Registration
            onSuccess={() => {
              nextStep(steps.register);
            }}
            isBusiness={isBusiness}
          />
        );
      // case steps.phoneVerify:
      //   return (
      //     <PhoneVerification
      //       onSuccess={() => {
      //         nextStep(steps.phoneVerify);
      //       }}
      //     />
      //   );
      case steps.emailVerify:
        return (
          <EmailVerification
            onSuccess={() => {
              nextStep(steps.emailVerify);
            }}
          />
        );
      case steps.socialRegister:
        return (
          <SocialRegistration
            onSuccess={() => {
              nextStep(steps.socialRegister);
            }}
          />
        );
      case steps.success:
        return (
          <RegistrationSuccess
            onSuccess={() => {
              nextStep(steps.success);
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {step !== steps.success && <h3>Създайте профил</h3>}
      {getRegistrationStep(step)}
      {step !== steps.success && (
        <div className="my-2 d-flex justify-content-between flex-wrap">
          <span
            className={`sidemenu-form-link${customClass}`}
            onClick={() => {
              setModalComponent('login');
            }}
          >
            Вход
          </span>
          <span
            className={`sidemenu-form-link${customClass}`}
            onClick={() => {
              setModalComponent('forgotPassword');
            }}
          >
            Забравена парола
          </span>
        </div>
      )}
    </>
  );
};

export default RegistrationSteps;
