import React, { useState } from 'react';
import { Link } from 'gatsby';

import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';
import { FaChevronUp } from '@react-icons/all-files/fa/FaChevronUp';
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft';

const Navigation = ({ items, bg = '', showSublinkIcon = true }) => {
  const [openedDropdowns, setOpenedDropdowns] = useState({});
  return (
    <>
      <LinksList
        links={items}
        onChange={(selectedOptions) => {
          setOpenedDropdowns(selectedOptions);
        }}
        showSublinkIcon={showSublinkIcon}
        openedDropdowns={openedDropdowns}
        bg={bg}
      />
    </>
  );
};
export default React.memo(Navigation);

const LinksList = ({
  links,
  onChange,
  openedDropdowns,
  extraClass = ' ',
  subLinkClicked = false,
  showSublinkIcon,
  bg,
}) => {
  const handleDropdownClicked = (dropdownId) => {
    if (openedDropdowns.hasOwnProperty(dropdownId)) {
      onChange({});
    } else {
      let openedDropdownsCopy = {};
      openedDropdownsCopy[dropdownId] = {};
      onChange(openedDropdownsCopy);
    }
  };

  const handleSublinkClicked = (mainLinkKey, subLinkProperty) => {
    let openedDropdownsCopy = { ...openedDropdowns };
    openedDropdownsCopy[mainLinkKey] = subLinkProperty;
    onChange(openedDropdownsCopy);
  };

  const linkHovered = (id) => {
    if (subLinkClicked) {
      onChange({ [id]: {} });
    } else {
      handleDropdownClicked(id);
    }
  };

  return (
    <ul className={`${extraClass}-container ${bg}`}>
      {links.map((link) => {
        return (
          <li
            id={link.id}
            key={link.id}
            className={`link_wrapper ${extraClass} ${openedDropdowns.hasOwnProperty(link.id) ? 'opened' : ''
              }`}
            onMouseEnter={() => {
              linkHovered(link.id);
            }}
            onMouseLeave={() => {
              linkHovered(link.id);
            }}
          >
            {' '}
            <LinkWrapper
              key={link.id}
              link={link}
              className="links"
              isItOpened={openedDropdowns && openedDropdowns.hasOwnProperty(link.id)}
              extraClass={extraClass}
              state={openedDropdowns}
              showSublinkIcon={showSublinkIcon}
              bg={bg}
            >
              {' '}
            </LinkWrapper>
            {link.subNav && link.subNav.length > 0 && openedDropdowns.hasOwnProperty(link.id) && (
              <LinksList
                extraClass={` ${link.className}  sublink`}
                links={link.subNav}
                openedDropdowns={openedDropdowns[link.id]}
                subLinkClicked={true}
                onChange={(subLinkProperty) => handleSublinkClicked(link.id, subLinkProperty)}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

const LinkWrapper = ({ link, isItOpened, extraClass, bg, showSublinkIcon }) => {
  return (
    <Link
      to={link.path}
      className={`links ${extraClass}-fchild  d-flex ${bg === 'white' ? 'black' : 'white'}`}
      state={link.state || null}
      style={{ display: 'block' }}
    >
      {link.image && (
        <span style={{ width: "35px", height: "35px", marginRight: '5px', transform: "translateY(-5px)" }} className="pl-2 d-flex justify-content-center">
          <img
            src={link.image}
            alt="Alt"
          ></img></span>
      )}
      <span className="mr-2" style={{ display: 'block' }}>
        {link.title.toUpperCase()}
      </span>
      {link.subNav &&
        showSublinkIcon &&
        (extraClass !== 'sublink' ? (
          <div>{isItOpened ? <FaChevronUp /> : <FaChevronDown />}</div>
        ) : (
          <div>{isItOpened ? <FaChevronLeft /> : <FaChevronRight />}</div>
        ))}
    </Link>
  );
};
