import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showDialog, hideDialog, toggleDialog } from "@a1-ict/core/dialogSlice";
import { selectIsOpen } from "@a1-ict/core/dialogSlice/selectors";

export const useDialog = (name = "") => {
  const isOpen = useSelector((state) => selectIsOpen(state, name));
  const dispatch = useDispatch();

  const open = useCallback(() => dispatch(showDialog({ name: name })), []);
  const close = useCallback(() => dispatch(hideDialog({ name: name })), []);
  const toggle = useCallback(() => dispatch(toggleDialog({ name: name })), []);

  return { isOpen, open, close, toggle };
};
