import React, { useState } from 'react';
import { Button, Modal, ModalBody, Col, Row } from 'reactstrap';
import Login from './Login';
import RegistrationSteps from './RegistrationSteps';
import ForgotPassword from './ForgotPassword';

import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';

const LoginModal = ({ modalSize = 'lg', onSuccessLogin }) => {
  const [modalComponent, setModalComponent] = useState('login');
  const { toggle, isOpen } = useDialog('login');

  const closeModal = () => {
    setModalComponent('login');
    toggle();
  };

  return (
    <Modal isOpen={isOpen} scrollable={true} size={modalSize} toggle={closeModal} backdrop={false}>
      <ModalBody className="py-0 pl-md-0">
        <Row>
          <Col md="6" className="login-register-modal-image-container">
            <div className="login-register-modal-image"></div>
          </Col>
          <Col xs="12" md="6" className="py-2">
            <Row>
              <Col>
                <Button onClick={closeModal} className="float-right" close></Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="px-4">
                  {modalComponent === 'login' && (
                    <>
                      <div className="modal-form-container">
                        <h3>Влезте в профила си</h3>
                        <Login providers={[]} buttonType="text" onSuccessLogin={onSuccessLogin} />
                      </div>
                      <div className="modal-form-control">
                        <span
                          className="modal-form-link"
                          onClick={() => {
                            setModalComponent('register');
                          }}
                        >
                          Регистрирайте се тук!
                        </span>
                        <span
                          className="modal-form-link"
                          onClick={() => {
                            setModalComponent('forgotPassword');
                          }}
                        >
                          Забравена парола
                        </span>
                      </div>
                    </>
                  )}
                  {modalComponent === 'register' && (
                    <>
                      <div className="modal-form-container">
                        <RegistrationSteps
                          emailVerification
                          onSuccess={onSuccessLogin}
                          setModalComponent={setModalComponent}
                          initialStep="clientType"
                        />
                      </div>
                    </>
                  )}
                  {modalComponent === 'forgotPassword' && (
                    <>
                      <div className="modal-form-container">
                        <ForgotPassword />
                      </div>
                      <div className="modal-form-control">
                        <span
                          className="modal-form-link"
                          onClick={() => {
                            setModalComponent('login');
                          }}
                        >
                          Вход
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default LoginModal;
